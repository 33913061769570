<script setup lang="ts">
const { data, status, error } = useLazyAsyncData("item", async () => {
  return await useApiFetch("/auth/user")
})
</script>

<template>
  <div v-if="status === 'pending'">
    <FetchLoaderPage />
  </div>
  <div v-else-if="error">{{ error }}</div>
  <div v-else>data {{ data }}</div>
</template>

<style lang="scss"></style>
